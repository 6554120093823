import axios from 'axios';
import { useEffect, useState } from 'react';
import { baseURL } from '../../App';
import moment from 'moment';

interface User {
  personalDetails: {
    fullName: string;
    dateOfBirth: string;
    emailAddress: string;
    phoneNumber: string;
    cityAndCountry: string;
    gender: string;
    password: string;
    centerCode: string;
  };
  educationAndSkills: {
    educationLevel: string;
    fieldOfStudy: string;
    topSkills: string[];
    certifications: string;
    languages: string;
  };
  interestsAndNiche: {
    interests: string[];
    hobbies: string;
    globalProblem: string;
  };
  personalVisionAndGoals: {
    motivation: string;
    dreamCareer: string;
    shortTermGoals: string[];
    longTermGoals: string[];
  };
  // Add other sections similarly
  bio: string;
  experience: string;
  skills: string;
  designation: string;
}

const About = () => {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<User | null>(null);

  useEffect(() => {
    if (userId) {
      getDetails();
    }
  }, [userId]);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white rounded-lg shadow-lg space-y-6">
      <h1 className="text-3xl font-semibold text-gray-800">Profile</h1>

      {/* User Details */}
      <div className="space-y-4">
        {/* Personal Details */}
        <div className="text-gray-700">
          <p className="font-medium">Full Name:</p>
          <p>{data.personalDetails.fullName}</p>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-700">
            <p className="font-medium">Phone Number:</p>
            <p>{data.personalDetails.phoneNumber}</p>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Date of Birth:</p>
            <p>{moment(data.personalDetails.dateOfBirth).format("D MMM, YYYY")}</p>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Gender:</p>
            <p>{data.personalDetails.gender}</p>
          </div>
        </div>

        {/* Education and Skills */}
        <div className="space-y-4">
          <div className="text-gray-700">
            <p className="font-medium">Education Level:</p>
            <p>{data.educationAndSkills.educationLevel}</p>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Field of Study:</p>
            <p>{data.educationAndSkills.fieldOfStudy}</p>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Top Skills:</p>
            <ul className="list-disc pl-5">
              {data.educationAndSkills.topSkills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Certifications:</p>
            <p>{data.educationAndSkills.certifications}</p>
          </div>
          <div className="text-gray-700">
            <p className="font-medium">Languages:</p>
            <p>{data.educationAndSkills.languages}</p>
          </div>
        </div>

        {/* Bio */}
        <div className="text-gray-700">
          <p className="font-medium">Bio:</p>
          <p>{data.bio}</p>
        </div>

        {/* Experience */}
        <div className="text-gray-700">
          <p className="font-medium">Experience:</p>
          <ul className="list-disc pl-5">
            <li>{data.experience}</li>
          </ul>
        </div>

        {/* Designation */}
        <div className="text-gray-700">
          <p className="font-medium">Designation:</p>
          <p>{data.designation}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
