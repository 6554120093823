import { Map, Download } from 'lucide-react';
import { jsPDF } from 'jspdf';

export function RoadmapSection() {
  const roadmap = {
    'Short-term (1-3 months)': [
      'Complete Advanced React Course',
      'Implement 3 portfolio projects',
      'Achieve 7 hours daily sleep routine'
    ],
    'Mid-term (3-6 months)': [
      'Obtain AWS Cloud Certification',
      'Launch personal blog',
      'Network with 20 industry professionals'
    ],
    'Long-term (6-12 months)': [
      'Lead a team project',
      'Speak at 2 tech conferences',
      'Master system design principles'
    ],
    'Bounty Board': [
      'Coming Soon !'
    ],
    'Job Openings': [
      'Sales',
      'Marketing',
      'E-commerce',
      'Journalism',
      'Content Creation',
      'Hotel Management Graduates',
      'Data Analytics',
      'Information Technology',
      'Accounts',
      'Legal',
      'Human Resources',
      'Intenal Consulting',
      'Product Design',
      'Tool Making & Design',
      'Sourcing & Supply Chain',
      'Manufacturing & Quality Control',
      'Stores & Despatch',
      'Executive Assistants',
      'Subject Matter Expert',
      'Influencer'
    ],
    'Incubations': [
      'Coming Soon !'
    ]
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    let yOffset = 20;

    // Title
    doc.setFontSize(20);
    doc.text('Growth Roadmap', 20, yOffset);
    yOffset += 20;

    // Content
    doc.setFontSize(12);
    Object.entries(roadmap).forEach(([period, goals]) => {
      doc.setFont('bold');
      doc.text(period, 20, yOffset);
      yOffset += 10;

      doc.setFont('normal');
      goals.forEach(goal => {
        doc.text(`• ${goal}`, 30, yOffset);
        yOffset += 10;
      });
      yOffset += 10;
    });

    doc.save('growth-roadmap.pdf');
  };

  return (
    <section id="roadmap" className="mb-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <div className="bg-green-100 p-3 rounded-full">
            <Map className="w-6 h-6 text-green-600" />
          </div>
          <h2 className="text-xl sm:text-2xl font-semibold">Growth Roadmap</h2>
        </div>
        <button
          onClick={downloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          <Download className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {Object.entries(roadmap).map(([period, goals]) => (
          <div
            key={period}
            className="bg-white p-4 sm:p-6 rounded-lg border border-gray-200 shadow-md"
          >
            <h3 className="text-lg sm:text-xl font-semibold mb-4">{period}</h3>
            <ul
              className={`space-y-3 ${goals.length > 5 ? 'max-h-40 overflow-y-auto' : ''}`}
            >
              {goals.map((goal, index) => (
                <li key={index} className="flex gap-2">
                  <div className="w-2 h-2 bg-green-500 rounded-full mt-2" />
                  <span className="text-gray-700 text-sm sm:text-base">{goal}</span>
                  {period === 'Job Openings' && (
                    <button className="border-none bg-gradient-to-r from-red-500 via-yellow-500 to-purple-500 bg-clip-text text-transparent">
                      Apply Now
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}
