

const Badges = () => {
  return (
    <div>
    No badges yet.
    </div>
  )
}

export default Badges
