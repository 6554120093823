import { formatDateTime, formatDate } from '../../../utils/formatters';
import { Course, Task } from '../../../utils/types';

interface CourseEventProps {
  course: Course;
}

interface TaskEventProps {
  task: Task;
}

export function CourseEvent({ course }: CourseEventProps) {
  return (
    <div className="flex items-center justify-between border-b pb-2">
      <div>
        <p className="font-medium">{course.title}</p>
        <p className="text-sm text-gray-500">
          Next Lecture: {formatDateTime(course.nextLecture)}
        </p>
      </div>
      <span className="text-blue-600 text-sm">{course.students} students</span>
    </div>
  );
}

export function TaskEvent({ task }: TaskEventProps) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="font-medium">{task.title}</p>
        <p className="text-sm text-gray-500">
          Due: {formatDate(task.deadline)}
        </p>
      </div>
      <span className={`px-2 py-1 rounded text-xs ${
        task.priority === 'high' ? 'bg-red-100 text-red-800' :
        task.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
        'bg-green-100 text-green-800'
      }`}>
        {task.priority}
      </span>
    </div>
  );
}