import { ComponentType } from 'react';
import { formatCurrency } from '../../../utils/formatters';

interface DashboardCardProps {
  title: string;
  value: string | number;
  icon: ComponentType<{ className?: string }>;
  change?: number;
}

export default function DashboardCard({ title, value, icon: Icon, change }: DashboardCardProps) {
  const displayValue = typeof value === 'number' && title.toLowerCase().includes('revenue')
    ? formatCurrency(value)
    : value;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className="text-2xl font-semibold text-gray-900">{displayValue}</p>
        </div>
        <Icon className="w-8 h-8 text-gray-400" />
      </div>
      {change !== undefined && (
        <div className={`mt-2 flex items-center text-sm ${
          change >= 0 ? 'text-green-600' : 'text-red-600'
        }`}>
          <span>{change}% from last month</span>
        </div>
      )}
    </div>
  );
}