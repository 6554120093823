import { useState } from 'react';
import Calendar from 'react-calendar';
import { Calendar as CalendarIcon } from 'lucide-react';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export function CalendarSection() {
  const [value, setValue] = useState<Value>(new Date());
  
  const events = [
    { date: '2024-03-15', title: 'Project Presentation' },
    { date: '2024-03-20', title: 'Mentorship Session' },
    { date: '2024-03-25', title: 'Skill Assessment' },
  ];

  const tileContent = ({ date }: { date: Date }) => {
    const eventForDate = events.find(
      event => event.date === date.toISOString().split('T')[0]
    );
    
    return eventForDate ? (
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500"></div>
    ) : null;
  };

  return (
    <section id="calendar" className="mb-8">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-blue-100 p-3 rounded-full">
          <CalendarIcon className="w-6 h-6 text-blue-600" />
        </div>
        <h2 className="text-2xl font-semibold">Calendar</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <Calendar
            onChange={setValue}
            value={value}
            tileContent={tileContent}
            className="w-full"
          />
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Upcoming Events</h3>
          <div className="space-y-4">
            {events.map((event, index) => (
              <div key={index} className="flex items-start gap-3">
                <div className="w-2 h-2 bg-blue-500 rounded-full mt-2" />
                <div>
                  <p className="font-medium">{event.title}</p>
                  <p className="text-sm text-gray-500">{event.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
