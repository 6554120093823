import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  AcademicCapIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ShoppingBagIcon,
  ClipboardDocumentListIcon,
  UsersIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';
import logo from '../../images/logo.png';
import { LogOut, User } from 'lucide-react';
import { Modal } from 'antd';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        setTimeout(() => {
          navigate('/');
        }, 1000);
      },
    });
  };

  const navigation = [
    { name: 'Dashboard', href: '/', icon: ChartBarIcon },
    { name: 'Courses', href: '/courses', icon: AcademicCapIcon },
    { name: 'Projects', href: '/projects', icon: BriefcaseIcon },
    { name: 'Classes', href: '/classes', icon: BookOpenIcon },
    { name: 'Store', href: '/store', icon: ShoppingBagIcon },
    { name: 'Tasks', href: '/tasks', icon: ClipboardDocumentListIcon },
    { name: 'Team', href: '/team', icon: UsersIcon },
    { name: 'Profile', href: '/profile', icon: User },
    { icon: LogOut, name: 'Logout', onClick: () => handleLogout() },
  ];

  return (
    <div className="flex flex-col w-64 h-screen fixed">
      <div className="flex items-center justify-center h-16">
        <img src={logo} className="w-[130px] sm:w-[160px] h-[80px] sm:h-[80px]" />
      </div>
      <nav className="flex-1 px-2 py-4 space-y-2">
        {navigation.map((item) => {
          const Icon = item.icon;
          return item.onClick ? (
            <button
              key={item.name}
              onClick={item.onClick}
              className="flex items-center w-full px-4 py-2 text-sm rounded-lg text-black hover:bg-black/20"
            >
              <Icon className="w-5 h-5 mr-3" />
              {item.name}
            </button>
          ) : (
            <Link
              key={item.name}
              to={item.href}
              className={`flex items-center px-4 py-2 text-sm rounded-lg ${
                location.pathname === item.href
                  ? 'bg-gray-900 text-white'
                  : 'text-black hover:bg-black/20'
              }`}
            >
              <Icon className="w-5 h-5 mr-3" />
              {item.name}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}
