
/* eslint-disable @typescript-eslint/no-explicit-any */
import  { useState } from 'react';
import { Form, Input, Button, DatePicker, Upload, Select, Row, Col, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { baseURL } from '../../App';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const FreelancerEdit = () => {
  const userId = localStorage.getItem('userId');
  const [coverImage, setCoverImage] = useState<any>(null);
  const [profilePic, setProfilePic] = useState<any>(null);
const navigate=useNavigate()

  // Handle the change for cover image
  const handleCoverImageChange = (info:any) => {
    if (info.fileList.length > 0) {
      setCoverImage(info.fileList); // Keeps track of uploaded file(s)
    }
   
  };

  const handleProfilePicChange = (info: any) => {
    if (info.fileList.length > 0) {
      setProfilePic(info.fileList); // Keeps track of uploaded file(s)
    }
  };
  
  const onFinish = async (values: any) => {
    const formData = new FormData();
  
    if (profilePic && profilePic[0]?.originFileObj) {
      formData.append("profilePic", profilePic[0].originFileObj);
    }
    if (coverImage && coverImage[0]?.originFileObj) {
      formData.append("coverImage", coverImage[0].originFileObj);
    }
  
    // Append other fields to FormData
    formData.append("fullName", values.fullName);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("dateOfBirth", values.dateOfBirth);
    formData.append("gender", values.gender);
    formData.append("designation", values.designation);
    formData.append("bio", values.bio);
    formData.append("skills", values.skills);
    formData.append("educations", values.educations);
    formData.append("experience", values.experience);
  
    try {
      const response = await axios.put(
        `${baseURL}/user/updateDetails/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      notification.success({
        message: 'Update Successful',
        description: 'Your profile details have been updated successfully!',
        duration: 3, // Notification will disappear after 3 seconds
      });
  console.log(response)
      // Redirect to profile section after 3 seconds
      setTimeout(() => {
        navigate('/profile'); // Adjust the route path as per your app
      }, 3000);
    } catch (error: any) {
      // Show error notification
      notification.error({
        message: 'Update Failed',
        description: error.response?.data?.message || 'An error occurred while updating your profile. Please try again.',
        duration: 3,
      });
    }
  };


  return (
    <div className="container p-8">
      <Form onFinish={onFinish} layout="vertical">
        {/* Full Name */}
        <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: 'Please input your full name!' }]}>
          <Input />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true, message: 'Please input your phone number!' }]}>
          <Input />
        </Form.Item>

        {/* Date of Birth */}
        <Form.Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true, message: 'Please select your date of birth!' }]}>
          <DatePicker />
        </Form.Item>

        {/* Gender */}
        <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Please select your gender!' }]}>
          <Select>
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>

        {/* Profile Picture */}
        <Form.Item label="Profile Picture" name="profilePic" >
        <Upload beforeUpload={() => false} maxCount={1} listType="picture" onChange={handleProfilePicChange}>
        <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
        </Form.Item>

        {/* Cover Image */}
        <Form.Item label="Cover Image" name="coverImage" >
        <Upload beforeUpload={() => false} maxCount={1} listType="picture" onChange={handleCoverImageChange}>
        <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
        </Form.Item>

        {/* Designation */}
        <Form.Item label="Designation" name="designation">
          <Input />
        </Form.Item>

        {/* Bio */}
        <Form.Item label="Bio" name="bio">
          <TextArea rows={4} />
        </Form.Item>

        {/* Skills */}
        <Form.Item label="Skills" name="skills">
          <Input />
        </Form.Item>

        {/* Education */}
        <Form.Item label="Education" name="educations">
          <Input />
        </Form.Item>

        {/* Experience */}
        <Form.Item label="Experience" name="experience">
          <Input />
        </Form.Item>

        {/* Social Links */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Facebook" name={['socials', 'facebook']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="LinkedIn" name={['socials', 'linkedin']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="GitHub" name={['socials', 'github']}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Twitter" name={['socials', 'twitter']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="YouTube" name={['socials', 'youtube']}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FreelancerEdit;
