import { useState } from 'react';
import { LayoutDashboard, Brain, User, Menu, X, LogOut, Book } from 'lucide-react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'



export function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

 const handleLogout = () => {
        Modal.confirm({
            title: 'Confirm Logout',
            content: 'Are you sure you want to log out?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('role');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            },
        });
    };

  const menuItems = [
    { icon: LayoutDashboard, label: 'Overview',href:'/'},
    { icon: User, label: 'Profile', onClick:() => navigate("/profile")},
    { icon: Brain, label: 'Projects', onClick: () => navigate('/assessment/projects') },
    { icon: Book, label: 'Insight Hub', onClick: () => navigate('/insight-hub') },
    { icon: LogOut, label: 'Logout', onClick: () => handleLogout() },
  ];
  return (
    <>
      {/* Mobile Hamburger Button */}
      <button
        onClick={toggleSidebar}
        className="md:hidden fixed top-4 right-4 z-50 p-2 bg-white rounded-lg shadow-md"
      >
        {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {/* Overlay */}
      {isOpen && (
        <div
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={toggleSidebar}
        />
      )}

      {/* Sidebar */}
      <div
        className={`${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 fixed top-0 left-0 h-screen w-64 bg-white border-r border-gray-200 p-4 transition-transform duration-200 ease-in-out z-40`}
      >
        <div className="mb-8">
     <img src={logo} className='w-[130px] sm:w-[160px] h-[80px] sm:h-[80px]'/>
        </div>
        <nav className="space-y-1">
          {menuItems.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="flex items-center gap-3 cursor-pointer text-gray-600 hover:bg-gray-50 px-4 py-3 rounded-lg"
              onClick={(e) => {
                if (item.onClick) {
                  e.preventDefault(); // Prevent default link behavior for items with onClick
                  item.onClick();
                }
                setIsOpen(false);
              }}
            >
              <item.icon className="w-5 h-5" />
              <span>{item.label}</span>
            </a>
          ))}
        </nav>
      </div>
    </>
  );
}
