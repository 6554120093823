import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../App";
import moment from "moment";
import { Button, Drawer, Spin } from "antd";
import { formatDistanceToNow } from "date-fns";
import { debounce } from "lodash";
import { LeftCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface Article {
  _id: string;
  title: string;
  category: string;
  content: string;
  coverImage: string;
  createdBy: {
    profilePic?: string;
    fullName: string;
  };
  createdAt: string;
}

const DashboardContent = () => {
  const [getArticles, setGetArticles] = useState<Article[]>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate=useNavigate()

  useEffect(() => {
    fetchArticles(page);
    const handleScroll = debounce(checkScrollPosition, 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  const fetchArticles = async (page: number) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/article?page=${page}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setGetArticles((prev) => [...prev, ...response.data.articles]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(getArticles)

  const checkScrollPosition = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 100 >=
      document.documentElement.scrollHeight
    ) {
      setPage((prev) => prev + 1);
    }
  };

  const openArticleDrawer = (article: Article) => {
    setSelectedArticle(article);
    setDrawerVisible(true);
  };

  const closeArticleDrawer = () => setDrawerVisible(false);

  return (
    <div className="max-w-5xl mx-auto mt-6 space-y-8 px-4">
             <button onClick={()=>navigate('/')} className='mb-4 text-2xl'>
            <LeftCircleFilled/> Dashboard
        </button>
        <h2 className="text-2xl font-semibold mb-6">Community Blogs</h2>
      {getArticles?.map((article, index) => (
        <div
          key={index}
          className="bg-gradient-to-r from-white via-gray-100 to-gray-50 shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <img
            src={article.coverImage}
            alt={article.title}
            className="w-full h-64 object-contain bg-white/20"
          />
          <div className="p-4">
            <h2 className="text-2xl font-bold text-gray-800 hover:underline">
              {article.title}
            </h2>
            <p className="text-sm text-gray-500 mt-2">
              {moment(article.createdAt).format("D MMM, YYYY")} • by{" "}
              {article.createdBy.fullName}
            </p>
            <p className="mt-4 text-gray-700 text-ellipsis overflow-hidden line-clamp-3 mb-4">
              {article.content}
            </p>
          </div>
          <div className="flex justify-between items-center p-4 bg-gray-50">
            <Button
              type="primary"
              onClick={() => openArticleDrawer(article)}
              className="rounded-full px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white"
            >
              Read More
            </Button>
          </div>
        </div>
      ))}

      {loading && (
        <div className="flex justify-center mt-8">
          <Spin size="large" />
        </div>
      )}

      <Drawer
        title={selectedArticle?.title}
        placement="right"
        onClose={closeArticleDrawer}
        open={drawerVisible}
        width={600}
        className="drawer-content"
      >
        {selectedArticle && (
          <div>
            <img
              src={`https://prompt-api-206d.onrender.com/uploads/articleImages/${selectedArticle.coverImage}`}
              alt="cover"
              className="w-full h-52 object-cover rounded-lg mb-4"
            />
            <p className="text-base mb-4">{selectedArticle.content}</p>
            <div className="flex items-center">
              <img
                src={
                  selectedArticle.createdBy.profilePic
                    ? `${baseURL}/uploads/profileImages/profilePic/${selectedArticle.createdBy.profilePic}`
                    : "https://via.placeholder.com/130"
                }
                className="w-12 h-12 rounded-full mr-4"
                alt="Profile Pic"
              />
              <div>
                <p className="text-sm font-medium">
                  {selectedArticle.createdBy.fullName}
                </p>
                <p className="text-xs text-gray-500">
                  {selectedArticle.createdAt &&
                    formatDistanceToNow(new Date(selectedArticle.createdAt), {
                      addSuffix: true,
                    })}
                </p>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default DashboardContent;
