/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Brain } from 'lucide-react';
import { RadarChart } from './Charts/RadarChart';
import axios from 'axios';
import { baseURL } from '../../App';

interface BigFiveTraits {
  Conscientiousness?: number;
  Openness?: number;
  Extraversion?: number;
  Agreeableness?: number;
  Neuroticism?: number;
}

interface ParsedData {
  bigFive: BigFiveTraits;
  mbtiProfile: string;
}

export function PersonalitySection() {
  const [traits, setTraits] = useState<BigFiveTraits>({});
  const [mbtiProfile, setMbtiProfile] = useState<string>("");
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<Record<string, any>>([]);

  useEffect(() => {
    getData();
    handlembti();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.judgmentalAssessments);
    } catch (error) {
      console.log(error);
    }
  };

  const handlembti = async () => {
    try {
      const response = await axios.post('https://onboarding-fom.onrender.com/user/mbti', data);
      const parsedData = parseData(response.data);
      setTraits(parsedData.bigFive); // Set Big Five traits
      setMbtiProfile(parsedData.mbtiProfile); // Set MBTI profile
    } catch (error) {
      console.log(error);
    }
  };

  const parseData = (responseData: string): ParsedData => {
    const lines: string[] = responseData.split("\n").map(line => line.trim()).filter(line => line);

    const bigFive: BigFiveTraits = {};
    let mbtiProfile = "";

    lines.forEach((line: string) => {
      if (line.startsWith("*")) {
        const [key, value] = line.slice(1).split(":").map(part => part.trim());
        if (key && value) {
          bigFive[key as keyof BigFiveTraits] = parseInt(value.replace("%", ""), 10); // Remove % and convert to number
        }
      } else if (line.startsWith("Dominant MBTI Profile:")) {
        mbtiProfile = line.split(":")[1].trim(); // Extract MBTI profile
      }
    });

    return { bigFive, mbtiProfile };
  };

  const filteredTraits: Record<string, number> = Object.entries(traits).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, number>);

  return (
    <section id="personality" className="mb-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-purple-100 p-3 rounded-full">
          <Brain className="w-6 h-6 text-purple-600" />
        </div>
        <h2 className="text-2xl font-semibold">Personality Insights</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Big Five Traits</h3>
          <RadarChart 
            data={filteredTraits} 
            label="Personality Traits" 
            color="#9333ea"
          />
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">MBTI Profile: </h3>
          <ul className="space-y-2">
            {mbtiProfile && mbtiProfile.split(',').map((trait, index) => (
              <li key={index} className="flex items-center gap-2">
                <span className="w-2 h-2 bg-purple-600 rounded-full" />
                <span className="text-gray-700">{trait.trim()}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
