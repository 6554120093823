/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { User } from 'lucide-react';
import { baseURL } from '../../App';
import axios from 'axios';

interface User {
  fullName: string;
  dateOfBirth: string;
  emailAddress: string;
  phoneNumber: string;
  cityAndCountry: string;
  gender: string;
  password: string;
  centerCode: string;
}

export function PersonalDetails() {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<User>({} as User);
  const [profile, setProfile] = useState<string>("");
  const [greeting, setGreeting] = useState<string>("");

  useEffect(() => {
    getDetails();
    updateGreeting();
  }, [userId]);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.personalDetails);
      setProfile(response.data.profilePic);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      setGreeting("Good Morning");
    } else if (hour >= 12 && hour < 17) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  };

  return (
    <section id="profile" className="mb-8 px-4 sm:px-6 md:px-8">
      <div className="flex items-center gap-4 mb-6 flex-wrap">
        <div className="bg-blue-100 p-3 rounded-full">
          <div className="w-[80px] sm:w-[100px] md:w-[120px] h-[80px] sm:h-[100px] md:h-[120px] rounded-full overflow-hidden border-4 border-white shadow-md">
            <img src={profile} alt="Profile Pic" className="w-full h-full object-cover" />
          </div>
        </div>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold">{greeting}, {data.fullName || "User"}!</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <div className="flex flex-col gap-2">
          <p><strong>Date of Birth:</strong> {data.dateOfBirth}</p>
          <p><strong>Email:</strong> {data.emailAddress}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p><strong>Phone Number:</strong> {data.phoneNumber}</p>
          <p><strong>City & Country:</strong> {data.cityAndCountry}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p><strong>Gender:</strong> {data.gender}</p>
          <p><strong>Center Code:</strong> {data.centerCode}</p>
        </div>
      </div>
    </section>
  );
}
