import { Course, Task } from '../../../utils/types';
import { CourseEvent, TaskEvent } from './EventItem';

interface UpcomingEventsProps {
  courses: Course[];
  tasks: Task[];
}

export default function UpcomingEvents({ courses, tasks }: UpcomingEventsProps) {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold mb-4">Upcoming Events</h2>
      <div className="space-y-4">
        {courses.map(course => (
          <CourseEvent key={course.id} course={course} />
        ))}
        {tasks.map(task => (
          <TaskEvent key={task.id} task={task} />
        ))}
      </div>
    </div>
  );
}