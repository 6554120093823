import DashboardCard from './DashboardCard';
import {
  UsersIcon,
  AcademicCapIcon,
  CurrencyDollarIcon,
  ClipboardDocumentListIcon
} from '@heroicons/react/24/outline';

export default function StatCards() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <DashboardCard
        title="Total Students"
        value="156"
        icon={UsersIcon}
        change={12}
      />
      <DashboardCard
        title="Active Courses"
        value="8"
        icon={AcademicCapIcon}
        change={5}
      />
      <DashboardCard
        title="Monthly Revenue"
        value="$24,500"
        icon={CurrencyDollarIcon}
        change={15}
      />
      <DashboardCard
        title="Pending Tasks"
        value="12"
        icon={ClipboardDocumentListIcon}
      />
    </div>
  );
}