/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SalesData } from '../../../utils/types';
import { formatCurrency } from '../../../utils/formatters';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface RevenueChartProps {
  salesData: SalesData[];
}

export default function RevenueChart({ salesData }: RevenueChartProps) {
  const data = {
    labels: salesData.map(data => data.month),
    datasets: [
      {
        label: 'Revenue',
        data: salesData.map(data => data.revenue),
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Monthly Revenue'
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0;
            return `Revenue: ${formatCurrency(value)}`;
          }
        }
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <Line options={options} data={data} />
    </div>
  );
}