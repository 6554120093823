import { format } from 'date-fns';

export const formatDateTime = (date: Date): string => {
  return format(date, 'PPp');
};

export const formatDate = (date: Date): string => {
  return format(date, 'PP');
};

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};