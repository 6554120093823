import  { useState } from 'react';
import StatCards from '../../components/Entreprenuer-Dashboard/Dashboard/StatsCard';
import UpcomingEvents from '../../components/Entreprenuer-Dashboard/Events/UpcomingEvent';
import RevenueChart from '../../components/Entreprenuer-Dashboard/Charts/RevenueCharts';
import { Course, Task, SalesData } from '../../utils/types';
import { sampleCourses, sampleTasks, sampleSalesData } from '../../components/Entreprenuer-Dashboard/data';
import Sidebar from '../../components/Entreprenuer-Dashboard/Sidebar';

export default function Dashboard() {
  const [courses] = useState<Course[]>(sampleCourses);
  const [tasks] = useState<Task[]>(sampleTasks);
  const [salesData] = useState<SalesData[]>(sampleSalesData);

  return (
    <>
    <Sidebar/>
    <div className="ml-64 p-8">
      <h1 className="text-2xl font-bold mb-6">Dashboard Overview</h1>
      <StatCards />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <RevenueChart salesData={salesData} />
        </div>
        <div>
          <UpcomingEvents courses={courses} tasks={tasks} />
        </div>
      </div>
    </div>   
    </>
 
  );
}