import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import GoogleTranslate from './utils/GoogleTranslate.jsx'
 
createRoot(document.getElementById('root')!).render(<BrowserRouter>
    <GoogleTranslate/>
<App />
</BrowserRouter>)
