
// import { useEffect, useState } from 'react';
// import {  message } from 'antd';
// import { Link, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
// import {
//     CalendarOutlined,
//     PieChartOutlined,
//     FileTextOutlined,
//     UserOutlined,
//     LogoutOutlined,
//     MenuOutlined,
//     CloseOutlined,
// } from '@ant-design/icons';
// import logo from '../images/logo.png';
// import { baseURL } from '../App';
// import axios from 'axios';
// import PrivateRoute from '../utils/PrivateRoute';
// import Article from '../pages/Insight Hub/Article';
// import Project from '../pages/Assignment/Project/Project';
// import ProjectDetail from '../pages/Assignment/Project/ProjectDetail';
// import Profile from '../pages/Profile/Profile';
// import DashboardContent from '../pages/DashboardContent';
import { Sidebar } from './Sidebar';

import { PersonalitySection } from './PersonalitySection';
import { HealthSection } from './HealthSection';
import { SkillsSection } from './SkillsSection';
import { RoadmapSection } from './RoadmapSection';
import { CalendarSection } from './CalendarSection';
import { PersonalDetails } from './PersonalDetails';
import { Courses } from './Courses';
import InsightHubSection from './InsightHubSection';

// const { Header, Content } = Layout;

// interface User {
//     profilePic?: string;
//     role?: string;
// }

const Dashboard = () => {
    // const location = useLocation();
    // const userId = localStorage.getItem('userId');
    // const [user, setUser] = useState<User>({});
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     fetchUserDetails();
    // }, []);

    // const fetchUserDetails = async () => {
    //     try {
    //         const response = await axios.get(`${baseURL}/user/${userId}`);
    //         setUser(response.data);
    //     } catch (error: any) {
    //         message.error(error.response?.data?.message);
    //     }
    // };

   
    
    // type drawerMenuItems= 
    // | { key: string; label: string; icon: JSX.Element; onClick: () => void } // With onClick
    // | { key: string; label: string; icon: JSX.Element };

    // const navMenuItems = [
    //     { key: '/', label: 'Dashboard', icon: <CalendarOutlined /> },
    //     ...(user.role === 'super-admin'
    //         ? [{ key: '/marketing-dashboard', label: 'Marketing Dashboard', icon: <PieChartOutlined /> }]
    //         : []),
    //     { key: '/assessment/projects', label: 'Projects', icon: <FileTextOutlined /> },
    //     ...(user.role !== 'freelancer'
    //         ? [
    //               { key: '/users/admin', label: 'Admin', icon: <UserOutlined /> },
    //               { key: '/users/staff', label: 'Staff', icon: <UserOutlined /> },
    //           ]
    //         : []),
    //     { key: '/insight-hub', label: "Insight's Hub", icon: <FileTextOutlined /> },
    // ];

    // const drawerMenuItems = [
    //     ...navMenuItems,
    //     { key: 'profile', label: 'Profile', icon: <UserOutlined />, onClick: () => navigate('/profile') },
    //     { key: 'logout', label: 'Logout', icon: <LogoutOutlined />, onClick: handleLogout },
    // ];

    // const toggleDrawer = () => {
    //     setIsDrawerOpen(!isDrawerOpen);
    // };

    return (
        // <Layout className="min-h-screen">
        //     <Header className="flex justify-between items-center border-b bg-white px-4 py-2 md:px-6 md:py-3 fixed top-0 left-0 right-0 z-20">
        //         <div className="flex items-center space-x-2 md:space-x-4">
        //             <img src={logo} alt="Logo" className="h-8 w-auto" />
        //             <div className="hidden lg:flex">
        //                 <Menu mode="horizontal" selectedKeys={[location.pathname]} className="flex-grow">
        //                     {navMenuItems.map((item) => (
        //                         <Menu.Item key={item.key} icon={item.icon}>
        //                             <Link to={item.key}>{item.label}</Link>
        //                         </Menu.Item>
        //                     ))}
        //                 </Menu>
        //             </div>
        //         </div>
        //         <div className="hidden lg:block">
        //             <Dropdown
        //                 overlay={
        //                     <Menu>
        //                         <Menu.Item key="profile" onClick={() => navigate('/profile')} icon={<UserOutlined />}>
        //                             Profile
        //                         </Menu.Item>
        //                         <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        //                             Logout
        //                         </Menu.Item>
        //                     </Menu>
        //                 }
        //             >
        //                 <img
        //                     src={user.profilePic}
        //                     alt="Profile"
        //                     className="h-8 w-8 md:h-10 md:w-10 rounded-full object-cover cursor-pointer border border-white"
        //                 />
        //             </Dropdown>
        //         </div>
        //         <Button className="lg:hidden" type="text" icon={isDrawerOpen ? <CloseOutlined /> : <MenuOutlined />} onClick={toggleDrawer} />
        //     </Header>

        //     <Drawer
        //         title="Menu"
        //         placement="right"
        //         onClose={toggleDrawer}
        //         open={isDrawerOpen}
        //         className="lg:hidden"
        //     >
        //         <Menu mode="vertical" selectedKeys={[location.pathname]}>
        //             {drawerMenuItems.map((item) => (
        //                 <Menu.Item key={item.key} icon={item.icon} onClick={(item as any).onClick || undefined}>
        //                     {item.label}
        //                 </Menu.Item>
        //             ))}
        //         </Menu>
        //     </Drawer>

        //     <Content className="p-2 md:p-2 flex-grow mt-[65px]">
        //         <Routes>
        //             <Route path="/dashboard" element={<DashboardContent />} />
        //             <Route path="/marketing-dashboard" element={<PrivateRoute><div>Marketing Dashboard</div></PrivateRoute>} />
        //             <Route path="/assessment/projects" element={<PrivateRoute><Project /></PrivateRoute>} />
        //             <Route path="/assessment/projects/:id" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
        //             <Route path="/insight-hub" element={<PrivateRoute><Article /></PrivateRoute>} />
        //             <Route path="/profile/*" element={<PrivateRoute><Profile /></PrivateRoute>} />
        //         </Routes>
        //     </Content>
        // </Layout>
        <div className="flex flex-col md:flex-row bg-gray-50">
        <Sidebar />
        <main className="flex-1 p-4 md:p-8 md:ml-64">
          <h1 className="text-3xl font-bold mb-8">Dashboard</h1>
          <PersonalDetails/>
          <PersonalitySection />
          <HealthSection />
          <SkillsSection />
          <Courses/>
          <RoadmapSection />
          <InsightHubSection/>
          <CalendarSection />
        </main>
      </div>
    );
};

export default Dashboard;
