

const Products = () => {
  return (
    <div>
      Products
    </div>
  )
}

export default Products
