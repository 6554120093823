/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Heart, Moon, Droplet, Activity } from 'lucide-react';
import axios from 'axios';
import { baseURL } from '../../App';

export function HealthSection() {
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState<Record<string, any>>([]);

  const dailyIntake = Math.ceil(data.waterIntake / 33.814);

  const healthMetrics = {
    bmi: data.bmi,
    sleep: data.sleepHours,
    hydration: dailyIntake,
    activity: data.exerciseFrequency
  };

  const getHealthStatus = (bmi: number) => {
    if (bmi < 18.5) return "Underweight";
    if (bmi < 25) return "Healthy";
    if (bmi < 30) return "Overweight";
    return "Obese";
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.healthAssessment);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section id="health" className="mb-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-red-100 p-3 rounded-full">
          <Heart className="w-6 h-6 text-red-600" />
        </div>
        <h2 className="text-2xl font-semibold">Health & Wellness</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">BMI</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.bmi}</p>
          <p className="text-sm text-gray-500">{getHealthStatus(healthMetrics.bmi)}</p>
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <div className="flex items-center gap-2 mb-2">
            <Moon className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Sleep</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.sleep}</p>
          <p className="text-sm text-gray-500">Average daily</p>
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <div className="flex items-center gap-2 mb-2">
            <Droplet className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Hydration</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.hydration} liters</p>
          <p className="text-sm text-gray-500">Daily intake</p>
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <div className="flex items-center gap-2 mb-2">
            <Activity className="w-5 h-5 text-gray-600" />
            <h3 className="font-medium">Activity</h3>
          </div>
          <p className="text-2xl font-semibold">{healthMetrics.activity}</p>
          <p className="text-sm text-gray-500">Lifestyle</p>
        </div>
      </div>
    </section>
  );
}
