import AllRoutes from './utils/AllRoutes'
export const baseURL ="https://swanirbhar-org-in.onrender.com" 

function App() {
  return (
    <>
    <AllRoutes/>
    </>
 
  )
}
export default App

