

const Forum = () => {
  return (
    <div>
      Forum
    </div>
  )
}

export default Forum
