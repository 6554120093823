

const EntrepreneurEdit = () => {
  return (
    <div>
      EntrepreneurEdit
    </div>
  )
}

export default EntrepreneurEdit
