

const StartupDetails = () => {
  return (
    <div>
      Startup Details
    </div>
  )
}

export default StartupDetails
