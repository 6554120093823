import { Trophy } from 'lucide-react';
import { DoughnutChart } from './Charts/DoughnutChart';

export function SkillsSection() {
  const skills = {
    'Problem Solving': 90,
    'Communication': 85,
    'Leadership': 75,
    'Technical Skills': 88,
    'Creativity': 82
  };

  const skillColors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    '#9966FF'
  ];

  const iqScore = 128;

  return (
    <section id="skills" className="mb-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-yellow-100 p-3 rounded-full">
          <Trophy className="w-6 h-6 text-yellow-600" />
        </div>
        <h2 className="text-2xl font-semibold sm:text-3xl">Skills & Aptitude</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">Core Skills</h3>
          <DoughnutChart data={skills} colors={skillColors} />
        </div>

        <div className="bg-white p-6 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-4 sm:text-xl">IQ Assessment</h3>
          <div className="flex items-center justify-center h-48">
            <div className="text-center">
              <p className="text-4xl font-bold text-yellow-600 sm:text-5xl">{iqScore}</p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base">IQ Score</p>
              <p className="text-sm text-gray-600 mt-4 sm:text-base">
                Top {Math.round((1 - (iqScore - 100) / 100) * 100)}th percentile
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
