import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface RadarChartProps {
  data: Record<string, number>;
  label: string;
  color: string;
}

export function RadarChart({ data, label, color }: RadarChartProps) {
  const chartData = {
    labels: Object.keys(data).map(key => key.charAt(0).toUpperCase() + key.slice(1)),
    datasets: [
      {
        label,
        data: Object.values(data),
        backgroundColor: `${color}33`,
        borderColor: color,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        min: 0,
        max: 100,
        ticks: { stepSize: 20 },
      },
    },
    maintainAspectRatio: true,  // Ensure the aspect ratio is maintained
  };

  return (
    <div className="w-full h-[300px] sm:h-[350px] md:h-[400px] lg:h-[450px]">
      <Radar data={chartData} options={options} />
    </div>
  );
}
