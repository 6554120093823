import { Course, Task, SalesData } from '../../utils/types';

export const sampleCourses: Course[] = [
  {
    id: '1',
    title: 'Web Development Bootcamp',
    nextLecture: new Date('2024-01-10T15:00:00'),
    students: 25,
    progress: 65
  },
  {
    id: '2',
    title: 'Digital Marketing Masterclass',
    nextLecture: new Date('2024-01-12T14:00:00'),
    students: 18,
    progress: 40
  }
];

export const sampleTasks: Task[] = [
  {
    id: '1',
    title: 'Review Project Proposals',
    deadline: new Date('2024-01-15'),
    priority: 'high',
    completed: false
  },
  {
    id: '2',
    title: 'Prepare Course Material',
    deadline: new Date('2024-01-20'),
    priority: 'medium',
    completed: false
  }
];

export const sampleSalesData: SalesData[] = [
  { month: 'Jan', revenue: 12000 },
  { month: 'Feb', revenue: 15000 },
  { month: 'Mar', revenue: 18000 },
  { month: 'Apr', revenue: 16000 },
  { month: 'May', revenue: 21000 },
  { month: 'Jun', revenue: 24000 }
];