import React, { useEffect } from 'react';

export default function GoogleTranslate () {
  useEffect(() => {
    // Check if the Google Translate script is already loaded
    if (!document.getElementById('google-translate-script')) {
      const script = document.createElement('script');
      script.id = 'google-translate-script';
      script.type = 'text/javascript';
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.body.appendChild(script);
    }

    // Initialize Google Translate once the script is loaded
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en', // Default language of your page
          includedLanguages: 'hi,bn,as,gu,ml,mr,ne,or,pa,sa,sd,ta,te,ur', // Languages you want to support (Add more languages as needed)
          layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL, // Simple layout style
          autoDisplay: false, // Do not auto display widget, you can control when to show it
        },
        'google_translate_element', // The ID where the widget will be injected
      );
    };

    return () => {
      const script = document.getElementById('google-translate-script');
      if (script) script.remove();
      delete window.googleTranslateElementInit;
    };
  }, []);

  return (
    <div     style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    }}>
      <div id="google_translate_element"></div> {/* This is where the Google Translate widget will be rendered */}
    </div>
  );
};


