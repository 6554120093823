

const Team = () => {
  return (
    <div>
      Team
    </div>
  )
}

export default Team
