import { Routes, Route, Navigate } from "react-router-dom";
import LoginForm from "../pages/LoginForm";
import RegisterForm from "../pages/RegisterForm";
import ForgotPassword from "../pages/ForgotPassword";
import VerifyCode from "../pages/VerifyCode";
import ResetPassword from "../pages/Resetpassword";
import PrivateRoute from "./PrivateRoute";
import Article from "../pages/Insight Hub/Article";
import Project from "../pages/Assignment/Project/Project";
import Profile from "../pages/Profile/Profile";
import AllVideos from "../pages/AllVideos";
import DashboardContent from "../pages/DashboardContent";
import FreelancerDashboard from "../components/Freelancer-Dashboard/Dashboard";
import EntrepreneurDashboard from "../pages/Entreprenuer/Dashboard";
import ProjectDetail from "../pages/Assignment/Project/ProjectDetail";
// import VentureCapitalistDashboard from "../components/VentureCapitalist-Dashboard/Dashboard";
// import AdminDashboard from "../components/Admin-Dashboard/Dashboard";

const AllRoutes = () => {
    const isUser = localStorage.getItem("token") && localStorage.getItem("userId");
    const userRole = localStorage.getItem("role");

    // Function to determine which dashboard to show
    const getDashboardComponent = () => {
        switch (userRole) {
            case "freelancer":
                return <FreelancerDashboard />;
            case "entrepreneur":
                return <EntrepreneurDashboard />;
            case "venture_capitalist":
                return <EntrepreneurDashboard />;
            case "admin":
                return <EntrepreneurDashboard />;
            default:
                return <Navigate to="/signin" />; // Redirect to login if role is undefined
        }
    };

    return (
        <Routes>
            <Route path="/" element={isUser ? <Navigate to="/dashboard" /> : <Navigate to='/signin' />} />
            <Route path="/signin" element={<LoginForm />} />
            <Route path="/signup" element={<RegisterForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/insight-hub" element={<Article />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/assessment/projects" element={<Project />} />
            <Route path="/assessment/projects/:id" element={<ProjectDetail/>} />
            <Route path="/verify-otp" element={<VerifyCode />} />
            <Route path="/courses" element={<AllVideos />} />
            <Route path="/blogs" element={<DashboardContent />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard" element={<PrivateRoute>{getDashboardComponent()}</PrivateRoute>} />
            <Route path="/*" element={<Navigate to="/signin" />} />
        </Routes>
    );
};

export default AllRoutes;
