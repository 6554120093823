/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../App';
import { LeftCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';



export default function AllVideos() {
  const [videos, setVideos] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');
const [searchQuery, setSearchQuery] = useState<string>('');
const [data, setData] = useState<string>('');
const navigate =useNavigate()
  useEffect(() => {
   getDetails()
  }, []);

  
  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.educationAndSkills.fieldOfStudy);
      fetchVideos(response.data.educationAndSkills.fieldOfStudy);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVideos = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/user/videos`, {
        params: { query },
      });
      console.log(response.data)
      setVideos(response.data.videos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      fetchVideos(searchQuery);
    }
  };

  return (
    <div className="p-4">
        <button onClick={()=>navigate('/')} className='mb-4 text-2xl'>
            <LeftCircleFilled/> Dashboard
        </button>

        <h2 className="text-2xl font-semibold mb-6">Videos related to your field : {data}</h2>
             <form onSubmit={handleSearch} className="mb-4">
        <input
          type="text"
          placeholder="Search YouTube videos"
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          type="submit"
          className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Search
        </button>
      </form>
   
      {isLoading ? (
        <p className="text-center text-blue-500">Loading videos...</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {videos.map((video) => (
            <div
              key={video.id.videoId}
              className="bg-gray-100 p-4 rounded-lg shadow-lg"
            >
              <iframe
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                title={video.snippet.title}
                allowFullScreen
                className="w-full h-[200px] rounded-md"
              ></iframe>
              <p className="mt-2 text-sm font-semibold line-clamp-2">
                {video.snippet.title}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
