/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Book } from 'lucide-react';
import { Carousel } from 'antd';
import { baseURL } from '../../App';
import 'antd/dist/reset.css';
import { useNavigate } from 'react-router-dom';

export function Courses() {
  const userId = localStorage.getItem('userId');
  const [videos, setVideos] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<string>('');
  const [chunkSize, setChunkSize] = useState<number>(3); // Default chunk size
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
    updateChunkSize();
    window.addEventListener('resize', updateChunkSize);
    return () => {
      window.removeEventListener('resize', updateChunkSize);
    };
  }, []);

  const updateChunkSize = () => {
    const width = window.innerWidth;
    if (width < 640) setChunkSize(1); // Mobile screens
    else if (width < 1024) setChunkSize(2); // Tablet screens
    else setChunkSize(3); // Laptop and larger screens
  };

  const getDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      setData(response.data.educationAndSkills.educationLevel);
      fetchVideos(response.data.educationAndSkills.fieldOfStudy+' for '+response.data.educationAndSkills.educationLevel);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVideos = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/user/videos`, {
        params: {query},
      });
      setVideos(response.data.videos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const chunkVideos = (videos: any[], size: number) => {
    const chunks = [];
    for (let i = 0; i < videos.length; i += size) {
      chunks.push(videos.slice(i, i + size));
    }
    return chunks;
  };

  const videoChunks = chunkVideos(videos, chunkSize); // Dynamic chunk size

  return (
    <section id="courses" className="mb-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-blue-200 p-3 rounded-full">
          <Book className="w-6 h-6 text-white" />
        </div>
        <h2 className="text-2xl font-semibold">Courses</h2>
      </div>

      {/* Video Carousel */}
      <div className="mt-8 w-[100%] h-[300px]">
        {isLoading ? (
          <p className="text-center text-blue-500">Loading videos...</p>
        ) : (
          <Carousel
            autoplay
            autoplaySpeed={3000}
            dots={false}
            infinite
            className="w-full md:w-[1100px] mx-auto"
          >
            {videoChunks.map((chunk, index) => (
              <div key={index} className="flex carousel-container justify-around">
                {chunk.map((video) => (
                  <div
                    key={video.id.videoId}
                    className="bg-gray-100 p-4 h-[300px] rounded-lg shadow-lg w-[90%] md:w-[45%] lg:w-[30%] relative overflow-hidden"
                  >
                    <div className="mb-4 flex">
                      <iframe
                        src={`https://www.youtube.com/embed/${video.id.videoId}`}
                        title={video.snippet.title}
                        allowFullScreen
                        className="w-full h-[250px] sm:h-[180px] md:h-[200px] lg:h-[280px] rounded-md"
                      ></iframe>
                    </div>
                    {/* Title positioned at the bottom-left */}
                    <p className="absolute bottom-2 left-2 bg-black/5 text-white text-xs sm:text-sm font-semibold bg-opacity-60 px-2 py-1">
                      {video.snippet.title}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div>
        <button className='mt-7' onClick={() => navigate('/courses')}>
          <p className="text-blue-500 text-base sm:text-lg">
            Show more videos related to {data}
          </p>
        </button>
      </div>
    </section>
  );
}
